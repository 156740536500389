<template>
    <v-card
        class="mx-auto"
        color="#ffffd9"
        min-height="435"
        elevation="8"
        outlined
    >
        <v-card-title class="justify-center">PRODUTOS</v-card-title>
        <v-divider/>
        <v-card-text>
            <div v-for="item in comanda.produtos" :key="item.id">
                <v-row>
                    <v-col cols="8">
                        <b>{{ item.quantidade }}x</b> {{ item.descricao }}
                    </v-col>
                    <v-col cols="4">
                        R${{ getSubtotal(item) }}
                    </v-col>
                </v-row>
            </div>
        </v-card-text>
        <v-divider/>
        <v-card-text class="text-center">
            <div style="font-size: 14px;">
                Acréscimo <b>R$ {{ formatPrice(+comanda.acrescimo || 0) }}</b>
            </div>
            <div style="font-size: 14px;">
                Desconto <b>R$ {{ formatPrice(+comanda.desconto || 0) }}</b>
            </div>
            <div style="font-size: 14px;">
                Taxa de entrega <b>R$ {{ formatPrice(+comanda.valor_frete || 0) }}</b>
            </div>
            <div style="font-size: 22px; margin-top: 10px;">
                VALOR TOTAL <b>R$ {{ formatPrice(+comanda.total || 0) }}</b>
            </div>
        </v-card-text>
        <v-card-actions>
            <!-- <v-btn color="primary" block @click="$emit('onBtnAdicionarProdutoClick')">ADICIONAR PRODUTO</v-btn> -->
        </v-card-actions>
    </v-card>
</template>

<script>
import { subtotal } from '@/utils/calc';

export default {
    name: 'ProductsList',

    props: ['value'],

    data: () => ({
        comanda: {},
    }),

    watch: {
        value(p) {
            p.id && (this.comanda = p);
        },
    },

    methods: {
        getSubtotal(item) {
            const value = subtotal(item);

            return this.formatPrice(value);
        },
    },
}
</script>

<style scoped>
.class-on-data-table table {
    table-layout: fixed;
}

.flex-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    padding: 0;
    margin: 0;
    list-style: none;
}

.v-card__title  {
    font-size: 16px;
    font-weight: bold;
}

.col {
    padding: 6px;
}

.front-label {
    color: #212121;
    font-size: 15px;
}

.value-label {
    color: #212121;
    font-size: 15px;
    font-weight: bold;
}
</style>
