<template>
    <v-card class="mx-auto" min-height="435" elevation="8" outlined>
        <v-card-title class="justify-center">EFETUAR PAGAMENTO</v-card-title>
        <v-divider/>
        <v-card-text>
            <v-row>
                <v-col>
                    <v-text-field
                        @click:append-outer="aplicarAcrescimoPercentual"
                        v-model="acrescimoPercentual"
                        label="Aplicar Acrésimo Percentual"
                        prefix="(%)"
                        placeholder="0.00"
                        v-mask="['#.##','##.##','###.##','####.##','#####.##']"
                        append-outer-icon="mdi-check-circle-outline"
                        hide-details
                        outlined
                        dense
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6">
                    <v-text-field
                        @change="onValueChange"
                        v-model="comanda.desconto"
                        label="Desconto"
                        prefix="R$"
                        placeholder="0.00"
                        v-mask="['#.##','##.##','###.##','####.##','#####.##']"
                        hide-details
                        outlined
                        dense
                    />
                </v-col>
                <v-col cols="6">
                    <v-text-field
                        @change="onValueChange"
                        v-model="comanda.acrescimo"
                        label="Acréscimo"
                        prefix="R$"
                        placeholder="0.00"
                        v-mask="['#.##','##.##','###.##','####.##','#####.##']"
                        hide-details
                        outlined
                        dense
                    />
                </v-col>
            </v-row>
            <v-divider class="my-6"/>
            <v-row>
                <v-col cols="6">
                    <v-text-field
                        v-model="paymentValue"
                        label="Valor a Pagar"
                        placeholder="0.00"
                        prefix="R$"
                        v-mask="['#.##','##.##','###.##','####.##','#####.##']"
                        hide-details
                        outlined
                        dense
                    />
                </v-col>
                <v-col cols="6">
                    <v-text-field
                        v-model="valueGave"
                        label="Valor Entregue"
                        placeholder="0.00"
                        prefix="R$"
                        v-mask="['#.##','##.##','###.##','####.##','#####.##']"
                        hide-details
                        outlined
                        dense
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6">
                    <v-select
                        @change="onChangeFormaPgto"
                        v-model="formaPagamento"
                        :items="formasPagamento"
                        label="Forma de Pagamento"
                        item-value="id"
                        item-text="descricao"
                        outlined
                        dense
                    />
                </v-col>
                <v-col cols="6">
                    <v-text-field
                        :value="troco"
                        label="Troco"
                        placeholder="0.00"
                        prefix="R$"
                        v-mask="['#.##','##.##','###.##','####.##','#####.##']"
                        readonly
                        filled
                        hide-details
                        outlined
                        dense
                    />
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-btn
                @click="adicionarPagamento"
                class="white--text"
                color="cyan"
                block
                large
            >
                ADICIONAR PAGAMENTO
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { subtotalGeral } from '@/utils/calc';

export default {
    name: 'MakePayments',

    props: ['value', 'formasPagamento'],

    data: () => ({
        comanda: {},
        desconto: '',
        acrescimo: '',
        valueGave: '',
        paymentValue: '',
        formaPagamento: null,
        acrescimoPercentual: '',
    }),

    mounted() {
        this.acrescimoPercentual = localStorage.getItem('zm-checkout-acrescimo-percentual');
    },

    watch: {
        value: {
            handler(p) {
                p.id && (this.comanda = p);
                p.forma_pagamento && (this.formaPagamento = p.forma_pagamento);
                p.totalRestante && !this.paymentValue && (this.paymentValue = p.totalRestante.toFixed(2));
            },
            deep: true,
        },

        paymentValue(v) {
            this.valueGave = v;
        },
    },

    computed: {
        troco() {
            return (this.valueGave - this.paymentValue).toFixed(2);
        },

        getSubtotalGeral() {
            return subtotalGeral(this.value?.produtos || []);
        },
    },  

    methods: {
        adicionarPagamento() {
            if (!this.formaPagamento) {
                this.notify('Informe a Forma de Pagamento', 'warning');
                return;
            }

            if (!this.paymentValue) {
                this.notify('Informe o Valor', 'warning');
                return;
            }

            if (!this.valueGave) {
                this.notify('Informe o Valor Entregue', 'warning');
                return;
            }

            if (+this.paymentValue > +this.valueGave) {
                this.notify('Valor a Pagar não pode ser maior que o Valor Entregue', 'warning');
                return;
            }

            if (+this.comanda.totalPago >=  +this.comanda.total) {
                this.notify('Valor Total já Liquidado', 'warning');
                return;
            }

            !this.comanda.pagamentos && (this.comanda.pagamentos = []);

            this.comanda.pagamentos.push({
                index: this.comanda.pagamentos.length,
                forma_pagamento: this.formaPagamento,
                valor: this.paymentValue,
                valor_entregue: this.valueGave,
                troco: this.troco,
            });

            this.formaPagamento = null;
            this.valueGave = null;
            this.$emit('onPayment');

            this.paymentValue = this.comanda.totalRestante.toFixed(2);
        },

        onValueChange() {
            this.$emit('onPayment');
        },

        aplicarAcrescimoPercentual() {
            this.value.acrescimo = (this.value.total * this.acrescimoPercentual / 100).toFixed(2);
            this.$emit('onPayment');
            localStorage.setItem('zm-checkout-acrescimo-percentual', this.acrescimoPercentual);
            this.notify('Acréscimo aplicado');
        },

        onChangeFormaPgto() {
            this.value.acrescimo = this.valorAcrescimo(this.formaPagamento);
            this.onValueChange();
        },

        valorAcrescimo(formaPgtoId) {
            if (!formaPgtoId) {
                return 0;
            }

            const formaPgto = this.formasPagamento.find(f => f.id == formaPgtoId);

            const percentual = +(formaPgto.acrescimo_percentual || 0),
                valor = +(formaPgto.acrescimo_valor) || 0;

            let acrescimo = 0;

            valor && (acrescimo += valor);
            percentual && (acrescimo += this.getSubtotalGeral * percentual / 100);

            return (acrescimo || 0).toFixed(2);
        },
    }
}
</script>

<style scoped>
.class-on-data-table table {
    table-layout: fixed;
}

.flex-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    padding: 0;
    margin: 0;
    list-style: none;
}

.v-card__title  {
    font-size: 16px;
    font-weight: bold;
}

.col {
    padding: 6px;
}

.front-label {
    color: #212121;
    font-size: 15px;
}

.value-label {
    color: #212121;
    font-size: 15px;
    font-weight: bold;
}

::v-deep .v-text-field__prefix {
    margin-top: 0;
}
</style>
