<template>
    <v-dialog v-model="show" persistent max-width="400">
        <v-card v-if="isNotaAprovada">
            <v-card-title class="headline">
                Imprmir Nota?
                <v-spacer/>
                <v-btn icon @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-actions>
                <v-btn @click="close" text>
                    Agora não
                </v-btn>
                <v-spacer/>
                <v-btn 
                    @click="openLink"
                    text
                    color="primary"
                >
                    Visualizar
                </v-btn>
                <v-spacer/>
                <v-btn 
                    @click="imprimirNfe" 
                    :loading="loading" 
                    color="success"
                    large
                >
                    <v-icon class="mr-2">mdi-printer</v-icon>
                    Imprimir
                </v-btn>
            </v-card-actions>
        </v-card>

        <v-card v-else>
            <v-card-title class="headline">
                Emitir Nota Fiscal?
                <v-spacer/>
                <v-btn icon @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-actions>
                <v-btn @click="close" text>
                    Agora não
                </v-btn>
                <v-spacer/>
                <v-btn 
                    @click="emitirNotaSaida('nfe')" 
                    :loading="loading" 
                    color="success"
                    large
                >
                    <v-icon class="mr-2">mdi-file-pdf-box</v-icon>
                    Emitir NF-e
                </v-btn>
                <v-btn 
                    @click="emitirNotaSaida('nfce')" 
                    :loading="loading" 
                    color="success"
                    large
                >
                    <v-icon class="mr-2">mdi-note</v-icon>
                    Emitir NFC-e
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { printDanfe } from '@/utils/utils';

export default {
    name: 'NfeDialog',

    props: ['show', 'value'],

    data: () => ({
        loading: false,
        nota: null,
    }),

    computed: {
        isNotaAprovada() {
            return this.nota?.status === 'aprovado';
        },
    },

    methods: {
        emitirNotaSaida(tipo) {
            this.loading = true;
            this.$http.post('fiscal/emissao-nota-saida', { pedido_id: this.value.id, tipo })
                .then(resp => {
                    if (resp.data.type == 'warning') {
                        this.notify(resp.data.msg, 'warning');
                        return;
                    }
                    const data = resp.data.data;

                    this.nota = data?.nfe;

                    if (data?.nfe?.status === 'aprovado') {
                        this.notify(`Aprovado, ${data.nfe.motivo}`);
                    } else {
                        this.notify(`${data.nfe.status} ${data.nfe.motivo}`, 'warning');
                    }
                })
                .catch(() => {
                    this.notify('Verifique sua conexão com a internet', 'warning');
                }).finally(() => (this.loading = false));
        },  

        imprimirNfe() {
            printDanfe(this.nota);
        },

        openLink() {
            window.open(this.nota.danfe, '_blank');
        },

        close() {
            this.$router.push('/caixa');
        },
    }
}
</script>

<style scoped>
</style>
