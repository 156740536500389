<template>
    <BaseContainer>
        <span slot="principal">
            <v-content>
                <v-card class="mx-2">
                    <v-toolbar class="ma-2" style="border-radius: 30px;">
                        <v-btn icon link to="/caixa">
                            <v-icon>mdi-arrow-left</v-icon>
                        </v-btn>
                        <v-spacer/>
                        <v-toolbar-title>Controle de Pagamentos</v-toolbar-title>
                        <v-spacer/>
                    </v-toolbar>
                    <v-card-text>
                        <div class="mb-4">
                            <h2 style="text-align: center;">PEDIDO {{ comanda.id }}</h2>
                        </div>
                        <v-row>
                            <v-col cols="12" md="4">
                                <ProductsList
                                    v-model="comanda"
                                />
                            </v-col>

                            <v-col cols="12" md="4">
                                <MakePayments
                                    v-model="comanda"
                                    :formasPagamento="formasPagamento"
                                    @onPayment="calcularTotais"
                                />
                            </v-col>

                            <v-col cols="12" md="4">
                                <v-card class="mx-auto" min-height="70" elevation="8" outlined>
                                    <v-card-title class="justify-center">PAGAMENTOS REALIZADOS</v-card-title>
                                    <v-divider/>
                                    <v-card-text v-if="hasPayments">
                                        <v-data-table
                                            fixed-header
                                            :headers="headers"
                                            :items="comanda.pagamentos"
                                            :mobileBreakpoint="0"
                                            height="250"
                                            :disable-pagination="true"
                                            :hide-default-footer="true"
                                        >
                                            <template v-slot:item.forma_pagamento="{ item }">
                                                {{ getFormaPagamento(item.forma_pagamento) }}
                                            </template>
                                            <template v-slot:item.valor="{ item }">
                                                <span>
                                                    R${{ formatPrice(+item.valor) }}
                                                </span>
                                            </template>
                                            <template v-slot:item.valor_entregue="{ item }">
                                                <span style="color: green;">
                                                    R${{ formatPrice(+item.valor_entregue) }}
                                                </span>
                                            </template>
                                            <template v-slot:item.troco="{ item }">
                                                <span style="color: #ab0000;">
                                                    R${{ formatPrice(+item.troco) }}
                                                </span>
                                            </template>
                                            <template v-slot:item.action="{ item }">
                                                <v-btn @click="removerPagamento(item.index)" icon>
                                                    <v-icon color="red">mdi-close</v-icon>
                                                </v-btn>
                                            </template>
                                        </v-data-table>
                                    </v-card-text>
                                    <v-card-text v-else class="text-center my-4" style="height: 250px;">
                                        Nenhum pagamento realizado
                                    </v-card-text>
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="6" class="pa-1">
                                                <v-chip color="orange" label text-color="white" style="width: 210px;">
                                                    Desconto<b style="margin-left: 4px;">R${{ formatPrice(+comanda.desconto || 0) }}</b>
                                                </v-chip>
                                            </v-col>
                                            <v-col cols="6" class="pa-1">
                                                <v-chip color="indigo" label text-color="white" style="width: 210px;">
                                                    Acréscimo
                                                    <b style="margin-left: 4px;">R${{ formatPrice(+comanda.acrescimo || 0) }}</b>
                                                </v-chip>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="6" class="pa-1">
                                                <v-chip color="pink" label text-color="white" style="width: 210px;">
                                                    Restante<b style="margin-left: 4px;">R${{ formatPrice(+comanda.totalRestante || 0) }}</b>
                                                </v-chip>
                                            </v-col>
                                            <v-col cols="6" class="pa-1">
                                                <v-chip color="cyan" label text-color="white" style="width: 210px;">
                                                    Total Pago
                                                    <b style="margin-left: 4px;">R${{ formatPrice(+comanda.totalPago || 0) }}</b>
                                                </v-chip>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>

                        <!-- <whatsapp-dialog :show="dialogPagamentoFinalizado" :comanda="comanda"/> -->
                        <NfeDialog 
                            :show="nfeDialog" 
                            v-model="comanda"
                        />

                        <v-btn
                            block
                            x-large
                            color="green accent-4"
                            class="white--text mt-4"
                            @click="finalizar"
                            :loading="loading"
                        >
                            <v-icon>mdi-{{ isOrderPaid() ? 'credit-card-outline' : 'content-save' }}</v-icon>
                            {{ isOrderPaid() ? 'FINALIZAR' : 'SALVAR' }}
                        </v-btn>
                    </v-card-text>
                </v-card>
            </v-content>
        </span>
    </BaseContainer>
</template>

<script>
import BaseContainer from '@/components/BaseContainer'
// import WhatsappDialog from './WhatsappDialog';
import NfeDialog from './NfeDialog';
import ProductsList from './ProductsList';
import MakePayments from './MakePayments';
import { mapState } from 'vuex';
import { hasModule } from '@/utils/module';

export default {
    name: 'Checkout',

    components: {
        BaseContainer,
        // WhatsappDialog,
        NfeDialog,
        ProductsList,
        MakePayments,
    },

    data: () => ({
        comanda: {},
        paymentsData: {},
        // dialogPagamentoFinalizado: false,
        nfeDialog: false,
        loading: false,
        headers: [
            { text: 'Forma Pgto', value: 'forma_pagamento' },
            { text: 'Valor a Pagar', value: 'valor' },
            { text: 'Valor Entregue', value: 'valor_entregue' },
            { text: 'Troco', value: 'troco' },
            { text: 'Cancelar', value: 'action', sortable: false, align: 'end'},
        ],
    }),

    computed: {
        ...mapState([
            'formasPagamento',
        ]),

        hasPayments() {
            return this.comanda?.pagamentos?.length;
        },

        hasFiscalModule() {
            return hasModule('FISCAL');
        },
    },

    async mounted() {
        const data = this.$store.getters.getData;
        await this.consultar(data.id);

        if (!this.comanda.id) {
            this.$router.push('/caixa');
        }

        this.calcularTotais();

        this.$store.commit('setData', {});
    },

    methods: {
        async consultar(id) {
            if (!id) {
                this.notify('Pedido não encontrado', 'warning');
                return;
            }

            this.$root.$emit('loading', true);
            await this.$http.get(`pedidos/${id}`).then(resp => {
                this.comanda = resp.data;

                this.comanda.totalOld = this.comanda.total;
                this.comanda.descontoOld = this.comanda.desconto;
                this.comanda.acrescimoOld = this.comanda.acrescimo;
            })
            .catch(() => (this.notify('Verifique sua conexão com a internet', 'warning')))
            .finally(() => (this.$root.$emit('loading', false)));
        },

        isOrderPaid() {
            return this.comanda.totalRestante <= 0;
        },

        finalizar() {
            const data = {
                pedido_id: this.comanda.id,
                pagamentos: this.comanda.pagamentos,
                desconto: this.comanda.desconto,
                acrescimo: this.comanda.acrescimo,
            };

            this.loading = true;
            this.$http.post('pagamentos', data).then(resp => {
                if (resp.data.type === 'warning') {
                    this.notify(resp.data.msg, 'warning')
                    return;
                }

                const { data } = resp.data;

                if (data.finalizado) {
                    this.notify('Pedido finalizado');

                    if (this.hasFiscalModule) {
                        this.nfeDialog = true;
                    } else {
                        this.$router.push('/caixa');
                    }
                    return;
                }

                this.notify('Salvo com sucesso');
                this.$router.push('/caixa');
            })
            .catch(() => (this.notify('Verifique sua conexão com a internet!', 'warning')))
            .finally(() => (this.loading = false));
        },

        removerPagamento(index) {
            this.comanda.pagamentos = this.comanda.pagamentos.filter(e => e.index != index);

            this.calcularTotais();
        },

        onPaymentAdd() {
            this.calcularTotais();
        },

        calcularTotais() {
            this.comanda.totalPago = 0;
            this.comanda.totalRestante = 0;

            this.comanda?.pagamentos?.forEach(p => (this.comanda.totalPago += +p.valor));

            const fmt = num => +num || 0;

            let comandaTotal = fmt(this.comanda?.totalOld),
                desconto = fmt(this.comanda?.desconto),
                acrescimo = fmt(this.comanda?.acrescimo),
                descontoOld = fmt(this.comanda?.descontoOld),
                acrescimoOld = fmt(this.comanda?.acrescimoOld),
                totalPago = +fmt(this.comanda?.totalPago).toFixed(2);

            this.comanda.totalRestante = (
                comandaTotal - desconto + descontoOld + acrescimo - acrescimoOld - totalPago
            ) || 0;

            this.comanda.total = this.comanda.totalRestante + totalPago;

            this.$forceUpdate();
        },

        getFormaPagamento(id) {
            return this.formasPagamento.find(e => e.id == id)?.descricao || '';
        },
    }
}
</script>

<style scoped>
.class-on-data-table table {
    table-layout: fixed;
}

.flex-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    padding: 0;
    margin: 0;
    list-style: none;
}

.v-card__title  {
    font-size: 16px;
    font-weight: bold;
}

.front-label {
    color: #212121;
    font-size: 15px;
}

.value-label {
    color: #212121;
    font-size: 15px;
    font-weight: bold;
}
</style>
